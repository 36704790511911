/** @jsxImportSource @emotion/react */
import { ClassNames, useTheme } from "@emotion/react";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { formatInTimeZone } from "date-fns-tz";

import { Typography } from "@rewards-web/shared/components/typography";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

type DateRangeType = "monthly" | "quarterly";

type JSONSerializableDateRange = {
  startDate: number;
  endDate: number;
  type: DateRangeType;
};

interface DateRangeSelectFieldProps {
  value: JSONSerializableDateRange | undefined;
  options: {
    monthly: { startDate: number; endDate: number }[];
    quarterly: { startDate: number; endDate: number }[];
  };
  onChange: (dateRange: JSONSerializableDateRange) => void;
  timezone: string;
}

const optionToValueStr = ({
  startDate,
  endDate,
  type,
}: JSONSerializableDateRange) => JSON.stringify({ startDate, endDate, type });
// control order of props so stringify is determinsitc

const valueStrToOption = (str: string): JSONSerializableDateRange =>
  JSON.parse(str) as JSONSerializableDateRange;

const optionToLabel = (option: JSONSerializableDateRange, timezone: string) => {
  switch (option.type) {
    case "monthly":
      return formatInTimeZone(option.startDate, timezone, "MMMM yyyy");
    case "quarterly": {
      const quarterPart = formatInTimeZone(
        option.startDate,
        timezone,
        "QQQ yyyy"
      );
      const startMonthPart = formatInTimeZone(
        option.startDate,
        timezone,
        "MMM d"
      );
      const endMonthPart = formatInTimeZone(option.endDate, timezone, "MMM d");
      return `${quarterPart} (${startMonthPart} - ${endMonthPart})`;
    }
    default:
      assertNever(option.type);
  }
};

export function DateRangeSelectField({
  value,
  options,
  onChange,
  timezone,
}: DateRangeSelectFieldProps) {
  const label = "Date range";

  const theme = useTheme();

  const optionsChildren = (() => {
    const quarterlyOptions = options.quarterly.map((quarter) => {
      const option = { ...quarter, type: "quarterly" as const };
      const value = optionToValueStr(option);
      return (
        <MenuItem key={value} value={value}>
          <Typography component="span">
            {optionToLabel(option, timezone)}
          </Typography>
        </MenuItem>
      );
    });
    const monthlyOptions = options.monthly.map((month) => {
      const option = { ...month, type: "monthly" as const };
      const value = optionToValueStr(option);
      return (
        <MenuItem key={value} value={value}>
          {optionToLabel(option, timezone)}
        </MenuItem>
      );
    });
    const allOptions = []; // includes quarterly and monthly options
    if (quarterlyOptions.length > 0) {
      allOptions.push(
        <ListSubheader disableSticky>
          <Typography
            component="span"
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Quarterly
          </Typography>
        </ListSubheader>,
        ...quarterlyOptions
      );
    }
    if (monthlyOptions.length > 0) {
      allOptions.push(
        <ListSubheader disableSticky>
          <Typography
            component="span"
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Monthly
          </Typography>
        </ListSubheader>,
        ...monthlyOptions
      );
    }
    return allOptions;
  })();

  return (
    <ClassNames>
      {({ css, theme }) => (
        <Select
          input={
            <OutlinedInput
              label={label}
              notched={false}
              classes={{
                notchedOutline: "custom-notched-outline",
                root: css`
                  background-color: #fff;
                `,
              }}
            />
          }
          classes={{
            root: css`
              background-color: #fff;
              border-radius: 12px;
              padding: ${theme.spacing(1)} 0;
              height: 0.8em;
              width: 180px;

              &:focus {
                background-color: #fff;
                border-radius: 12px;
              }
            `,
          }}
          value={value ? optionToValueStr(value) : ""}
          startAdornment={
            <FontAwesomeIcon
              icon={faCalendar}
              color={theme.palette.grey[800]}
              css={css`
                margin-right: ${theme.spacing(1)};
              `}
            />
          }
          onChange={(event) => {
            const option = valueStrToOption(event.target.value as string);
            return onChange(option);
          }}
        >
          {optionsChildren.length > 0 ? (
            optionsChildren
          ) : (
            <MenuItem disabled>No date ranges available</MenuItem>
          )}
        </Select>
      )}
    </ClassNames>
  );
}
