import { faStars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PointsRedeemedInsightMetric as PointsRedeemedInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export type PointsRedeemedInsightMetricProps = {
  pointsPerDollar: number;
  currentPointsRedeemedInsightMetric:
    | PointsRedeemedInsightMetricType
    | undefined;
  previousPointsRedeemedInsightMetric:
    | PointsRedeemedInsightMetricType
    | undefined;
};

export function PointsRedeemedInsightMetric(
  props: PointsRedeemedInsightMetricProps
) {
  const currentPointsRedeemed =
    props.currentPointsRedeemedInsightMetric?.pointsRedeemed;

  const percentPointChange = (() => {
    const previousPointsRedeemed =
      props.previousPointsRedeemedInsightMetric?.pointsRedeemed;
    if (
      currentPointsRedeemed === undefined ||
      previousPointsRedeemed === undefined
    ) {
      return null;
    }
    return getPercentDifference(previousPointsRedeemed, currentPointsRedeemed);
  })();

  return (
    <InsightMetric
      label="Points redeemed"
      icon={<FontAwesomeIcon icon={faStars} />}
      tooltipText="Total points redeemed in the selected period, including pending rewards (giftcard, HSA or paycheck deposits)"
      value={
        currentPointsRedeemed !== undefined
          ? {
              type: "dollar",
              points: currentPointsRedeemed,
              pointsPerDollar: props.pointsPerDollar,
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
