/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { pick } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { Divider } from "@rewards-web/shared/components/divider";
import { Form } from "@rewards-web/shared/components/form";
import { Modal } from "@rewards-web/shared/components/modal/modal";
import { ModalActions } from "@rewards-web/shared/components/modal/modal-actions";
import { ModalContent } from "@rewards-web/shared/components/modal/modal-content";
import { ModalTitle } from "@rewards-web/shared/components/modal/modal-title";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Typography } from "@rewards-web/shared/components/typography";
import { RewardReportDataSetType } from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useSnackbar } from "@rewards-web/shared/modules/snackbar";
import { AppTheme } from "@rewards-web/shared/style/types";

import { useRole } from "../../../../shared/modules/role";
import { useDownloadReportModalDataQuery } from "./download-report-modal-data.generated";
import { DownloadReportSuccessModal } from "./download-report-success-modal";
import {
  DOWNLOAD_REPORT_FORM_KEY_TO_DATASET_TYPE,
  DOWNLOAD_REPORT_FORM_VALUE_KEYS,
  DownloadReportFormValues,
  getAvailableAdminDataSetsAndRangeOptions,
  getAvailableCaregiverDataSetsAndRangeOptions,
  serializeFormValuesForApi,
} from "./lib";
import { ReportCheckboxAndRangeField } from "./report-checkbox-and-range-field";
import { useSubmitReportRequestMutation } from "./submit-report-request.generated";

export interface InsightsDownloadReportModalProps {
  open: boolean;
  onClose(): void;

  /**
   * Allows for fixing the current date
   * (e.g. for use in storybook/tests)
   */
  now?: Date;
}

export function InsightsDownloadReportModal({
  open,
  onClose,
  now: nowProp = new Date(),
}: InsightsDownloadReportModalProps) {
  const track = useTrack();
  const snackbar = useSnackbar();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const now = useMemo(() => nowProp, []);

  const roleQuery = useRole();
  const modalQuery = useDownloadReportModalDataQuery({ onError: reportError });
  const [submitReportRequest] = useSubmitReportRequestMutation();

  const form = useForm<DownloadReportFormValues>({
    defaultValues: DOWNLOAD_REPORT_FORM_VALUE_KEYS.reduce(
      (prev, key) => ({
        ...prev,
        [key]: false,
      }),
      {}
    ),
  });

  const availableAdminDataSets = useMemo(() => {
    if (roleQuery.data && modalQuery.data) {
      return getAvailableAdminDataSetsAndRangeOptions(
        roleQuery.data,
        modalQuery.data,
        now
      );
    }

    return null;
  }, [roleQuery.data, modalQuery.data, now]);

  const availableCaregiverDataSets = useMemo(() => {
    if (roleQuery.data && modalQuery.data) {
      return getAvailableCaregiverDataSetsAndRangeOptions(
        roleQuery.data,
        modalQuery.data,
        now
      );
    }

    return null;
  }, [roleQuery.data, modalQuery.data, now]);

  const defaultValues = useMemo((): DownloadReportFormValues | null => {
    if (!availableAdminDataSets || !availableCaregiverDataSets) {
      return null;
    }

    return DOWNLOAD_REPORT_FORM_VALUE_KEYS.reduce<DownloadReportFormValues>(
      (prev, key) => ({
        ...prev,
        [key]: false,
        [`${key}Range`]: {
          preset:
            [...availableAdminDataSets, ...availableCaregiverDataSets].find(
              (dataSet) =>
                dataSet.type === DOWNLOAD_REPORT_FORM_KEY_TO_DATASET_TYPE[key]
            )?.defaultOption ?? null,
          customStart: null,
          customEnd: null,
        },
      }),
      {} as DownloadReportFormValues
    );
  }, [availableAdminDataSets, availableCaregiverDataSets]);

  // reset default values when api data loads
  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [form, defaultValues]);

  const handleSubmit = async (values: DownloadReportFormValues) => {
    const timezone = modalQuery.data!.getMyRewardsOrganization.timezone;

    const dataSets = serializeFormValuesForApi(
      [
        ...(availableAdminDataSets ?? []),
        ...(availableCaregiverDataSets ?? []),
      ],
      values,
      nowProp,
      timezone
    );

    if (dataSets.length === 0) {
      snackbar.show({
        severity: "error",
        message: "Please select one of the report options.",
      });
      return;
    }

    try {
      const res = await submitReportRequest({ variables: { dataSets } });

      setSuccessModalOpen(true);

      track("Submitted report request", {
        reportId: res.data?.submitReportRequest.id,
        selectedValues: values,
      });
    } catch (error) {
      reportError(error);
      snackbar.show({
        severity: "error",
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  const modalContents = (() => {
    if (roleQuery.error || modalQuery.error) {
      return (
        <>
          <ModalContent>
            <Alert
              severity="error"
              message="An unexpected error occurred. Please try again later."
            />
          </ModalContent>
          <ModalActions>
            <Button
              variant="outlined"
              label="Close"
              onClick={() => {
                onClose();
              }}
            />
          </ModalActions>
        </>
      );
    }

    if (!roleQuery.data || !modalQuery.data) {
      return <PageLoadingState />;
    }

    const { timezone } = modalQuery.data.getMyRewardsOrganization;

    return (
      <Form
        css={css`
          display: contents;
        `}
        onSubmit={form.handleSubmit(handleSubmit, (errors) => {
          snackbar.show({
            severity: "error",
            message: "Please correct date range errors and re-submit",
          });
        })}
        submitting={form.formState.isSubmitting}
      >
        <ModalContent
          css={css`
            &:first-child {
              padding-top: 0;
            }
          `}
        >
          <Typography
            variant="body"
            color="textPrimary"
            css={(theme: AppTheme) => css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            Select data to include in your report. When the report has been
            generated, CSV download links will be sent to your email.
          </Typography>
          {(availableAdminDataSets ?? []).length > 0 && (
            <>
              <Typography
                component="h2"
                variant="h3"
                color="textPrimary"
                fontWeight={600}
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                Admin report
              </Typography>

              {(availableAdminDataSets ?? []).map((dataSet) => {
                const props = {
                  ...pick(
                    dataSet,
                    "dateRangeOptions",
                    "customRangeVariant",
                    "customRangeMin",
                    "customRangeMax"
                  ),
                  control: form.control,
                  availableAt: dataSet.reportsAvailableAt ?? null,
                  timezone,
                  now,
                };

                switch (dataSet.type) {
                  case RewardReportDataSetType.RecognitionBudgetUtilizationByAdmin:
                    return (
                      <ReportCheckboxAndRangeField
                        {...props}
                        checkboxName="adminRecognitionBudgetUtilization"
                        checkboxLabel="Recognition budget utilization"
                        tooltipText="Summary of your organization's recognition budget utilization including budget spent, remaining budget for the selected period."
                        dateRangeName="adminRecognitionBudgetUtilizationRange"
                      />
                    );

                  case RewardReportDataSetType.LoginSummaryByAdmin:
                    return (
                      <ReportCheckboxAndRangeField
                        {...props}
                        checkboxName="adminLogin"
                        checkboxLabel="Admin log in activity"
                        tooltipText="Total admin logins and the most recent login date within the selected time period."
                        dateRangeName="adminLoginRange"
                      />
                    );

                  default:
                    assertNever(dataSet.type);
                    return null;
                }
              })}

              <Divider
                css={(theme: AppTheme) => css`
                  margin: ${theme.spacing(3)} 0;
                `}
              />
            </>
          )}

          {(availableCaregiverDataSets ?? []).length > 0 && (
            <>
              <Typography
                component="h2"
                variant="h3"
                color="textPrimary"
                fontWeight={600}
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(1)};
                `}
              >
                Caregiver report
              </Typography>

              {(availableCaregiverDataSets ?? []).map((dataSet) => {
                const props = {
                  ...pick(
                    dataSet,
                    "dateRangeOptions",
                    "customRangeVariant",
                    "customRangeMin",
                    "customRangeMax"
                  ),
                  control: form.control,
                  availableAt: dataSet.reportsAvailableAt ?? null,
                  timezone,
                  now,
                };

                switch (dataSet.type) {
                  case RewardReportDataSetType.EngagementByCaregiver:
                    return (
                      <ReportCheckboxAndRangeField
                        {...props}
                        checkboxName="caregiverEngagement"
                        checkboxLabel="Employee program activity"
                        tooltipText="Review caregiver engagement, point balances, redemptions, and milestone rewards within the selected period."
                        dateRangeName="caregiverEngagementRange"
                      />
                    );

                  case RewardReportDataSetType.EvvComplianceByCaregiver:
                    return (
                      <ReportCheckboxAndRangeField
                        {...props}
                        checkboxName="caregiverEVVCompliance"
                        checkboxLabel="EVV compliance"
                        tooltipText="Analyze visit counts and compliance rates within the selected time period."
                        dateRangeName="caregiverEVVComplianceRange"
                      />
                    );

                  default:
                    assertNever(dataSet.type);
                    return null;
                }
              })}
            </>
          )}
        </ModalContent>
        <ModalActions>
          <Button
            variant="outlined"
            label="Cancel"
            onClick={() => {
              onClose();
              track("Clicked homepage insights cancel download report");
            }}
          />
          <Button color="primary" label="Generate report" type="submit" />
        </ModalActions>
      </Form>
    );
  })();

  return (
    <>
      <Modal
        open={open && !successModalOpen}
        onClose={onClose}
        onExited={() => {
          // reset form values after modal close animation completes
          if (defaultValues) {
            form.reset(defaultValues);
          }
        }}
        width="640px"
      >
        <ModalTitle>Generate and download reports</ModalTitle>
        {modalContents}
      </Modal>
      <DownloadReportSuccessModal
        open={successModalOpen}
        onClose={() => {
          setSuccessModalOpen(false);
          onClose();
        }}
      />
    </>
  );
}
