import { invert } from "lodash";

import { AdminPermissionType } from "../modules/permissions/types";

export enum AdminPageName {
  home = "Home",
  candidates = "Candidates",
  redemptions = "Redemptions",
  recognition = "Recognition",
  employees = "Employees",
  jobs = "Jobs",
  admins = "Admins",
  settings = "Settings",
  onboarding = "Onboarding",
  draw = "Draw",
  surveys = "Surveys",
  hiring = "Hiring",
}

export type AdminSideMenuPageName = Exclude<
  AdminPageName,
  AdminPageName.onboarding
>;

export const ADMIN_SIDE_MENU_PAGE_ORDER: AdminSideMenuPageName[][] = [
  [AdminPageName.home],
  [AdminPageName.recognition, AdminPageName.redemptions, AdminPageName.draw],
  [AdminPageName.hiring, AdminPageName.jobs, AdminPageName.candidates],
  [
    AdminPageName.employees,
    AdminPageName.surveys,
    AdminPageName.admins,
    AdminPageName.settings,
  ],
];

export const ADMIN_PAGE_PATH_BY_NAME: { [key in AdminPageName]: string } = {
  [AdminPageName.home]: "/home",
  [AdminPageName.candidates]: "/candidates",
  [AdminPageName.redemptions]: "/redemptions",
  [AdminPageName.recognition]: "/recognition",
  [AdminPageName.employees]: "/employees",
  [AdminPageName.jobs]: "/jobs",
  [AdminPageName.admins]: "/admins",
  [AdminPageName.settings]: "/settings",
  [AdminPageName.onboarding]: "/onboarding",
  [AdminPageName.draw]: "/draw",
  [AdminPageName.surveys]: "/surveys",
  [AdminPageName.hiring]: "/hiring",
};

export const ADMIN_PAGE_BY_PATH_NAME = invert(ADMIN_PAGE_PATH_BY_NAME) as {
  [key: string]: AdminPageName;
};

export const ADMIN_PERMISSION_KEY_BY_PAGE: {
  [key in AdminPageName]: AdminPermissionType;
} = {
  [AdminPageName.home]: "home",
  [AdminPageName.candidates]: "candidates",
  [AdminPageName.redemptions]: "redemptions",
  [AdminPageName.recognition]: "recognitionPoints",
  [AdminPageName.employees]: "employees",
  [AdminPageName.jobs]: "jobs",
  [AdminPageName.admins]: "administrators",
  [AdminPageName.settings]: "settings",
  [AdminPageName.onboarding]: "onboarding",
  [AdminPageName.draw]: "draw",
  [AdminPageName.surveys]: "surveys",
  [AdminPageName.hiring]: "candidates",
};
