import { addDays, differenceInDays, isPast, min as minDate } from "date-fns";

/**
 * Returns the percent difference between two numbers.
 *
 * May return a negative percentage if $curr has decreased.
 * Rounds to the nearest whole number.
 * Returns infinity / negative infinity if the previous value was zero and the current value is not.
 */
export const getPercentDifference = (
  prev: number,
  curr: number
): number | null => {
  if (prev === 0) {
    if (curr === 0) {
      return 0;
    }
    if (curr > 0) {
      return Infinity;
    }
    return -Infinity;
  }
  return Math.round(((curr - prev) / prev) * 100);
};

/**
 * Returns the percentage point difference between two percentages, ie (5/100, 2/100) => -3
 *
 * May return a negative percentage if $curr has decreased.
 * Rounds to the nearest whole number.
 */
export const getDifferenceOfPercentages = ({
  prev,
  curr,
}: {
  prev: { numerator: number; denominator: number };
  curr: { numerator: number; denominator: number };
}) => {
  const [prevPercentagePoints, currPercentagePoints] = [prev, curr].map(
    ({ numerator, denominator }) => {
      if (denominator === 0) {
        return 0;
      }
      return (numerator / denominator) * 100;
    }
  );

  return Math.round(currPercentagePoints - prevPercentagePoints);
};

/**
 * Returns a previous range to be used for comparison.
 * If the current range is only partially elapsed,
 * we will return the same number of days in the previous
 * range for comparison.
 *
 * For example, if we are 10 days into Q3,
 * we will only use 10 days into Q2 for comparison.
 *
 * If the elapsed number of days in the current range exceeds
 * the number of days in the previous range (for example, we're 30
 * days into March but February only has 29 days), we will simply
 * return the previous range end.
 *
 * Does not consider timezones such as DST -- if we add days across
 * a DST boundary, we will not get +- 1 hour to account for DST.
 */
export const getPreviousRangeForComparison = ({
  previousFullRange,
  currentFullRange,
}: {
  previousFullRange: { startDate: Date; endDate: Date };
  currentFullRange: { startDate: Date; endDate: Date };
}): { startDate: Date; endDate: Date } => {
  if (isPast(currentFullRange.endDate)) {
    // we don't need to apply any partial range logic if the current range is not in-progress
    return previousFullRange;
  }
  // get the days elapsed in current range
  const daysElapsedInCurrentRange = differenceInDays(
    new Date(),
    currentFullRange.startDate
  );

  // apply days elapsed in current range to previous range start
  const previousEndDateEquivalent = addDays(
    previousFullRange.startDate,
    daysElapsedInCurrentRange
  );

  return {
    startDate: previousFullRange.startDate,
    endDate: minDate([previousFullRange.endDate, previousEndDateEquivalent]),
  };
};
