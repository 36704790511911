/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import {
  faInfoCircle,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleMinus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { Skeleton } from "@rewards-web/shared/components/skeleton";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { numberWithCommas } from "@rewards-web/shared/lib/format-numbers-with-commas";
import { shouldBeNever } from "@rewards-web/shared/lib/should-be-never";

export interface InsightMetricProps {
  label: string;
  icon: JSX.Element;
  tooltipText: NonNullable<ReactNode>;
  value:
    | { type: "loading" }
    | {
        type: "number";
        number: number;
      }
    | {
        type: "dollar";
        points: number;
        pointsPerDollar: number;
      }
    | {
        type: "percent";
        numerator: number;
        denominator: number;
      };
  // null if no previous value. Out of 100, not 1.
  percentPointChange: number | null;
}
export function InsightMetric(props: InsightMetricProps) {
  const theme = useTheme();

  const valueDisplay = (() => {
    switch (props.value.type) {
      case "loading":
        return <Skeleton width={100} height={35} />;
      case "number":
        return (
          <Typography variant="h1" fontWeight={700}>
            {numberWithCommas(props.value.number)}
          </Typography>
        );
      case "dollar":
        return (
          <Typography variant="h1" fontWeight={700}>
            {formatDollars(props.value.points / props.value.pointsPerDollar)}
          </Typography>
        );
      case "percent":
        return (
          <Typography variant="h1" fontWeight={800}>
            {props.value.denominator === 0
              ? 0
              : Math.round(
                  (props.value.numerator / props.value.denominator) * 100
                )}
            <Typography variant="h3" component="span" fontWeight={700}>
              {" "}
              %
            </Typography>
          </Typography>
        );
      default:
        shouldBeNever(props.value);
    }
  })();

  const diffBadge = (() => {
    if (props.percentPointChange === null) {
      return null;
    }
    const { icon, color } = (() => {
      if (props.percentPointChange > 0) {
        return {
          icon: faCircleArrowUp,
          color: theme.palette.success.main,
        };
      } else if (props.percentPointChange < 0) {
        return {
          icon: faCircleArrowDown,
          color: theme.palette.error.main,
        };
      }
      return {
        icon: faCircleMinus,
        color: theme.palette.grey[600],
      };
    })();
    return (
      <Tooltip
        title="This is a percentage difference from last period. If the current period is only partially complete, the same number of days is used to compare from the previous period."
        placement="bottom"
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${theme.spacing(0.4)};
            margin-left: ${theme.spacing(1.25)};
            margin-bottom: ${theme.spacing(-0.5)};
            height: 20px;
          `}
        >
          <FontAwesomeIcon icon={icon} color={color} width={13} />
          {
            Math.abs(props.percentPointChange) !== Infinity && (
              <Typography variant="footnote" color={color} fontWeight={700}>
                {Math.abs(Math.round(props.percentPointChange))}%
              </Typography>
            ) /* We only show the arrow if the percent point change is infinity */
          }
        </div>
      </Tooltip>
    );
  })();

  return (
    <Card
      css={css`
        padding: ${theme.spacing(2)};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
      variant="outlined"
    >
      <div
        css={css`
          display: flex;
          margin-bottom: ${theme.spacing(2)};
          height: 18px;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-right: ${theme.spacing(1)};
          `}
        >
          {props.icon}
        </div>
        <Typography
          variant="h3"
          fontWeight={700}
          css={css`
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          `}
          title={props.label}
        >
          {props.label}
        </Typography>
        <div
          css={css`
            margin-left: auto;
            padding-left: ${theme.spacing(1)};
          `}
        >
          <Tooltip title={props.tooltipText} placement="top">
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: flex-end;
        `}
      >
        {valueDisplay}
        {diffBadge}
      </div>
    </Card>
  );
}
