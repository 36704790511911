import { faMobile } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LoggedInUsersInsightMetric } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getDifferenceOfPercentages } from "../../../../utils";

const getFraction = ({
  usersWhoLoggedInCount,
  activeUserCount,
}: LoggedInUsersInsightMetric) => ({
  numerator: usersWhoLoggedInCount,
  denominator: activeUserCount,
});

export interface UserLoginsInsightMetricProps {
  currentUserLoginsInsightMetric: LoggedInUsersInsightMetric | undefined;
  previousUserLoginsInsightMetric: LoggedInUsersInsightMetric | undefined;
}

export function UserLoginsInsightMetric({
  currentUserLoginsInsightMetric,
  previousUserLoginsInsightMetric,
}: UserLoginsInsightMetricProps) {
  const percentPointChange = (() => {
    if (
      currentUserLoginsInsightMetric === undefined ||
      previousUserLoginsInsightMetric === undefined
    ) {
      return null;
    }
    return getDifferenceOfPercentages({
      prev: getFraction(previousUserLoginsInsightMetric),
      curr: getFraction(currentUserLoginsInsightMetric),
    });
  })();

  return (
    <InsightMetric
      label="Logins"
      icon={<FontAwesomeIcon icon={faMobile} />}
      tooltipText="Percentage of caregivers who logged into the Caribou app in the selected period"
      value={
        currentUserLoginsInsightMetric !== undefined
          ? {
              type: "percent",
              ...getFraction(currentUserLoginsInsightMetric),
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
