import { Typography } from "@rewards-web/shared/components/typography";
import { CostOfReferralsInsightMetric as CostOfReferralsInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export type CostOfReferralsInsightMetricProps = {
  pointsPerDollar: number;
  currentCostOfReferralsInsightMetric:
    | CostOfReferralsInsightMetricType
    | undefined;
  previousCostOfReferralsInsightMetric:
    | CostOfReferralsInsightMetricType
    | undefined;
};

export function CostOfReferralsInsightMetric(
  props: CostOfReferralsInsightMetricProps
) {
  const currentCostOfReferrals =
    props.currentCostOfReferralsInsightMetric?.pointsForReferrals;

  const percentPointChange = (() => {
    const previousCostOfReferrals =
      props.previousCostOfReferralsInsightMetric?.pointsForReferrals;
    if (
      currentCostOfReferrals === undefined ||
      previousCostOfReferrals === undefined
    ) {
      return null;
    }
    return getPercentDifference(
      previousCostOfReferrals,
      currentCostOfReferrals
    );
  })();

  return (
    <InsightMetric
      label="Cost of referrals"
      icon={
        <Typography variant="body" fontSize={18}>
          $
        </Typography>
      }
      tooltipText="Total points awarded to employees for referrals, regardless of hire date"
      value={
        currentCostOfReferrals !== undefined
          ? {
              type: "dollar",
              points: currentCostOfReferrals,
              pointsPerDollar: props.pointsPerDollar,
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
