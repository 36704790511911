/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

import { Alert } from "@rewards-web/shared/components/alert";
import { Button } from "@rewards-web/shared/components/button";
import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { Tooltip } from "@rewards-web/shared/components/tooltip";
import { Typography } from "@rewards-web/shared/components/typography";
import { assertNever } from "@rewards-web/shared/lib/assert-never";
import { useTrack } from "@rewards-web/shared/modules/analytics";
import { reportError } from "@rewards-web/shared/modules/error";
import { useFeatureFlag } from "@rewards-web/shared/modules/feature-flag";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DateRangeSelectField } from "./components/date-range-select-field";
import { InsightsDownloadReportModal } from "./download-report-modal";
import { EngagementInsightMetricGroup } from "./insight-metric-groups/engagement";
import { RecognitionInsightMetricGroup } from "./insight-metric-groups/recognition";
import { ReferralsInsightMetricGroup } from "./insight-metric-groups/referrals";
import { useInsightsPageDataQuery } from "./insights-page-data.generated";
import { getPreviousRangeForComparison } from "./utils";

interface InsightsContentProps {
  now?: Date;
}

export function InsightsContent({ now = new Date() }: InsightsContentProps) {
  const [selectedDateRange, setSelectedDateRange] = useState<
    | {
        startDate: Date;
        endDate: Date;
        type: "monthly" | "quarterly";
      }
    | undefined
  >();
  const [downloadReportModalOpen, setDownloadReportModalOpen] = useState(false);
  const track = useTrack();
  const downloadReportFeatureFlagEnabled = useFeatureFlag(
    "admin-app-homepage-insights-download-report-button-visible-temp"
  );

  const {
    data: dateRangeData,
    error: dateRangeError,
  } = useInsightsPageDataQuery({
    onCompleted: (data) => {
      if (data.insightDateRanges?.__typename === "LaunchedInsightDateRanges") {
        const latestMonthDateRange = data.insightDateRanges.monthly.at(-1);
        if (latestMonthDateRange) {
          setSelectedDateRange(
            (prev) =>
              prev ?? {
                startDate: new Date(latestMonthDateRange.startDate),
                endDate: new Date(latestMonthDateRange.endDate),
                type: "monthly",
              }
          );
        }
      }
    },
    onError: reportError,
    fetchPolicy: "cache-first",
  });

  const downloadReportButtonEnabled = dateRangeData
    ? // only enable button if org is launched
      Boolean(dateRangeData.getMyRewardsOrganization.launchedAt)
    : undefined;

  useEffect(() => {
    if (typeof downloadReportButtonEnabled === "boolean") {
      track("Viewed homepage insights download report button", {
        buttonEnabled: downloadReportButtonEnabled,
      });
    }
  }, [track, downloadReportButtonEnabled]);

  if (dateRangeError) {
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  const previousRange = ((): {
    startDate: Date;
    endDate: Date;
    type: "monthly" | "quarterly";
  } | null => {
    if (
      !dateRangeData ||
      !selectedDateRange ||
      dateRangeData.insightDateRanges.__typename ===
        "NotLaunchedInsightDateRanges"
    ) {
      return null;
    }
    const dateRanges = (() => {
      switch (selectedDateRange.type) {
        case "monthly":
          return dateRangeData.insightDateRanges.monthly;
        case "quarterly":
          return dateRangeData.insightDateRanges.quarterly;
        default:
          assertNever(selectedDateRange.type);
      }
    })();
    const selectedIndex = dateRanges.findIndex(
      (range) =>
        range.startDate.valueOf() === selectedDateRange.startDate.valueOf()
    );
    if (selectedIndex < 0) {
      reportError(new Error(`Date range not found: ${selectedDateRange}`));
      return null;
    }
    if (selectedIndex === 0) {
      // no previous value if the index is already the first element in array
      return null;
    }
    return {
      ...getPreviousRangeForComparison({
        previousFullRange: dateRanges[selectedIndex - 1],
        currentFullRange: selectedDateRange,
      }),
      type: selectedDateRange.type,
    };
  })();

  if (!dateRangeData) {
    return <PageLoadingState />;
  }

  return (
    <>
      <div
        css={(theme: AppTheme) => css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Typography variant="h2" color="textPrimary" fontWeight={700}>
          Insights
        </Typography>
        <div
          css={(theme: AppTheme) => css`
            display: flex;
            gap: ${theme.spacing(3)};
          `}
        >
          <DateRangeSelectField
            value={
              selectedDateRange
                ? {
                    startDate: selectedDateRange.startDate.valueOf(),
                    endDate: selectedDateRange.endDate.valueOf(),
                    type: selectedDateRange.type,
                  }
                : undefined
            }
            options={(() => {
              if (
                dateRangeData.insightDateRanges.__typename ===
                "NotLaunchedInsightDateRanges"
              ) {
                return { quarterly: [], monthly: [] };
              }
              return {
                quarterly: dateRangeData.insightDateRanges.quarterly,
                monthly: dateRangeData.insightDateRanges.monthly,
              };
            })()}
            timezone={dateRangeData.getMyRewardsOrganization.timezone}
            onChange={(dateRange: {
              startDate: number;
              endDate: number;
              type: "monthly" | "quarterly";
            }) =>
              setSelectedDateRange({
                startDate: new Date(dateRange.startDate),
                endDate: new Date(dateRange.endDate),
                type: dateRange.type,
              })
            }
          />

          {downloadReportFeatureFlagEnabled && (
            <>
              <Tooltip
                title="Reports become available after launch. Come back later to generate your reports."
                placement="bottom"
                disabled={downloadReportButtonEnabled}
              >
                <Button
                  color="primary"
                  width="auto"
                  size="medium"
                  onClick={() => {
                    setDownloadReportModalOpen(true);
                    track("Clicked homepage insights download report button");
                  }}
                  label="Download reports"
                  disabled={!downloadReportButtonEnabled}
                />
              </Tooltip>

              <InsightsDownloadReportModal
                open={downloadReportModalOpen}
                onClose={() => {
                  setDownloadReportModalOpen(false);
                }}
              />
            </>
          )}
        </div>
      </div>
      {selectedDateRange && (
        <div
          css={(theme: AppTheme) =>
            css`
              display: flex;
              flex-direction: column;
              gap: ${theme.spacing(3.75)};
            `
          }
        >
          <EngagementInsightMetricGroup
            currentRange={selectedDateRange}
            previousRange={previousRange}
            pointsPerDollar={
              dateRangeData!.getMyRewardsOrganization.pointsPerDollar
            }
            timezone={dateRangeData.getMyRewardsOrganization.timezone}
          />
          <ReferralsInsightMetricGroup
            currentRange={selectedDateRange}
            previousRange={previousRange}
            pointsPerDollar={
              dateRangeData!.getMyRewardsOrganization.pointsPerDollar
            }
            timezone={dateRangeData.getMyRewardsOrganization.timezone}
          />
          <RecognitionInsightMetricGroup
            currentRange={selectedDateRange}
            previousRange={previousRange}
            pointsPerDollar={
              dateRangeData!.getMyRewardsOrganization.pointsPerDollar
            }
            timezone={dateRangeData.getMyRewardsOrganization.timezone}
          />
        </div>
      )}
    </>
  );
}
