import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TotalHiresInsightMetric as TotalHiresInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export interface TotalHiresInsightMetricProps {
  currentTotalHiresInsightMetric: TotalHiresInsightMetricType | undefined;
  previousTotalHiresInsightMetric: TotalHiresInsightMetricType | undefined;
}

export function TotalHiresInsightMetric({
  currentTotalHiresInsightMetric,
  previousTotalHiresInsightMetric,
}: TotalHiresInsightMetricProps) {
  const currentTotalHires = currentTotalHiresInsightMetric?.hiredCandidateCount;
  const previousTotalHires =
    previousTotalHiresInsightMetric?.hiredCandidateCount;

  const percentPointChange = (() => {
    if (currentTotalHires === undefined || previousTotalHires === undefined) {
      return null;
    }
    return getPercentDifference(previousTotalHires, currentTotalHires);
  })();

  return (
    <InsightMetric
      label="Total hires"
      icon={<FontAwesomeIcon icon={faUsers} />}
      tooltipText="Total new hires referred through Caribou during the selected period"
      value={
        currentTotalHires !== undefined
          ? { type: "number", number: currentTotalHires }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
