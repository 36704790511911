import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InsightsPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InsightsPageDataQuery = (
  { __typename?: 'Query' }
  & { insightDateRanges: (
    { __typename: 'LaunchedInsightDateRanges' }
    & Pick<Types.LaunchedInsightDateRanges, 'id'>
    & { quarterly: Array<(
      { __typename?: 'InsightsQuarterlyDateRange' }
      & Pick<Types.InsightsQuarterlyDateRange, 'label' | 'year' | 'startDate' | 'endDate'>
    )>, monthly: Array<(
      { __typename?: 'InsightsMonthlyDateRange' }
      & Pick<Types.InsightsMonthlyDateRange, 'year' | 'startDate' | 'endDate'>
    )> }
  ) | (
    { __typename: 'NotLaunchedInsightDateRanges' }
    & Pick<Types.NotLaunchedInsightDateRanges, 'id'>
  ), getMyRewardsOrganization: (
    { __typename?: 'RewardsOrganization' }
    & Pick<Types.RewardsOrganization, 'id' | 'launchedAt' | 'timezone' | 'pointsPerDollar'>
  ) }
);


export const InsightsPageDataDocument = gql`
    query InsightsPageData {
  insightDateRanges: getAvailableInsightDateRanges {
    ... on NotLaunchedInsightDateRanges {
      id
      __typename
    }
    ... on LaunchedInsightDateRanges {
      id
      quarterly {
        label
        year
        startDate
        endDate
      }
      monthly {
        year
        startDate
        endDate
      }
      __typename
    }
  }
  getMyRewardsOrganization {
    id
    launchedAt
    timezone
    pointsPerDollar
  }
}
    `;

/**
 * __useInsightsPageDataQuery__
 *
 * To run a query within a React component, call `useInsightsPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsightsPageDataQuery(baseOptions?: Apollo.QueryHookOptions<InsightsPageDataQuery, InsightsPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsPageDataQuery, InsightsPageDataQueryVariables>(InsightsPageDataDocument, options);
      }
export function useInsightsPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsPageDataQuery, InsightsPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsPageDataQuery, InsightsPageDataQueryVariables>(InsightsPageDataDocument, options);
        }
export type InsightsPageDataQueryHookResult = ReturnType<typeof useInsightsPageDataQuery>;
export type InsightsPageDataLazyQueryHookResult = ReturnType<typeof useInsightsPageDataLazyQuery>;
export type InsightsPageDataQueryResult = Apollo.QueryResult<InsightsPageDataQuery, InsightsPageDataQueryVariables>;