import { faStars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { PointsAwardedInsightMetric as PointsAwardedInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export type PointsAwardedInsightMetricProps = {
  pointsPerDollar: number;
  currentPointsAwardedInsightMetric: PointsAwardedInsightMetricType | undefined;
  previousPointsAwardedInsightMetric:
    | PointsAwardedInsightMetricType
    | undefined;
};

export function PointsAwardedInsightMetric(
  props: PointsAwardedInsightMetricProps
) {
  const currentPointsAwarded =
    props.currentPointsAwardedInsightMetric?.pointsAwarded;

  const percentPointChange = (() => {
    const previousPointsAwarded =
      props.previousPointsAwardedInsightMetric?.pointsAwarded;
    if (
      currentPointsAwarded === undefined ||
      previousPointsAwarded === undefined
    ) {
      return null;
    }
    return getPercentDifference(previousPointsAwarded, currentPointsAwarded);
  })();

  return (
    <InsightMetric
      label="Points awarded"
      icon={<FontAwesomeIcon icon={faStars} />}
      tooltipText="Total points awarded and claimed in the selected period"
      value={
        currentPointsAwarded !== undefined
          ? {
              type: "dollar",
              points: currentPointsAwarded,
              pointsPerDollar: props.pointsPerDollar,
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
