import { Navigate } from "react-router-dom";

import { PageLoadingState } from "@rewards-web/shared/components/page-loading-state";
import { reportError } from "@rewards-web/shared/modules/error";
import { ErrorPage } from "@rewards-web/shared/pages/error";

import { useOrganizationLaunchStatusQuery } from "../../../shared/modules/launch/hooks/use-organization-launch-status/organization-launch-status.generated";
import { usePermissionsQuery } from "../../../shared/modules/permissions/hooks/use-permissions-query";
import { getDefaultPath } from "./get-default-path";

export function AdminIndexPage(): JSX.Element {
  const permissionsQuery = usePermissionsQuery();
  const launchStatusQuery = useOrganizationLaunchStatusQuery({
    fetchPolicy: "cache-first",
    onError: reportError,
  });

  if (launchStatusQuery.error || permissionsQuery.error) {
    return <ErrorPage />;
  }

  if (!launchStatusQuery.data || !permissionsQuery.data) {
    return <PageLoadingState />;
  }

  return (
    <Navigate
      to={getDefaultPath({
        permissions: permissionsQuery.data.getMyRewardsAdminUser.permissions,
        launched: launchStatusQuery.data.getMyRewardsOrganization.launched,
        onboardingDisabled:
          launchStatusQuery.data.getMyRewardsOrganization.onboardingState
            .disabledOnboarding === true,
      })}
      replace
    />
  );
}
