import { Alert } from "@rewards-web/shared/components/alert";
import { reportError } from "@rewards-web/shared/modules/error";

import { InsightMetricGroup } from "../../components/insight-metric-group";
import megaphoneIconUrl from "../../graphics/megaphone-icon.png";
import { CostOfReferralsInsightMetric } from "./insight-metrics/cost-of-referrals";
import { ReferralHoursWorkedInsightMetric } from "./insight-metrics/hours-worked";
import { TotalHiresInsightMetric } from "./insight-metrics/total-hires";
import { useReferralsInsightMetricGroupDataQuery } from "./referrals-metric-group-query.generated";

export interface ReferralsInsightMetricGroupProps {
  currentRange: {
    startDate: Date;
    endDate: Date;
  };
  previousRange: {
    startDate: Date;
    endDate: Date;
  } | null;
  timezone: string;
  pointsPerDollar: number;
}

export function ReferralsInsightMetricGroup({
  currentRange,
  previousRange,
  pointsPerDollar,
  timezone,
}: ReferralsInsightMetricGroupProps) {
  const skipPreviousRangeQuery = !previousRange;

  const currentReferralQuery = useReferralsInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: currentRange.startDate.valueOf(),
      endDate: currentRange.endDate.valueOf(),
    },
  });

  const previousReferralQuery = useReferralsInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: previousRange?.startDate.valueOf(),
      endDate: previousRange?.endDate.valueOf(),
    },
    skip: skipPreviousRangeQuery,
  });

  const queriesToValidate = [
    currentReferralQuery,
    ...(skipPreviousRangeQuery ? [] : [previousReferralQuery]),
    // no need to validate previous range queries if we skip them
  ];

  if (
    queriesToValidate.some(
      (query) => !query.loading && (query.error || !query.data)
    )
  ) {
    reportError(
      new Error(
        `Empty referral metric queries after loading: ${JSON.stringify(
          queriesToValidate.map(({ error }) => ({ error }))
        )}`
      )
    );
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <InsightMetricGroup
      label="Referrals"
      icon={<img src={megaphoneIconUrl} width={24} alt="megaphone" />}
      dateRange={currentRange}
      timezone={timezone}
    >
      <TotalHiresInsightMetric
        currentTotalHiresInsightMetric={
          currentReferralQuery.data?.getTotalHiresInsightMetric
        }
        previousTotalHiresInsightMetric={
          previousReferralQuery.data?.getTotalHiresInsightMetric
        }
      />
      <CostOfReferralsInsightMetric
        pointsPerDollar={pointsPerDollar}
        currentCostOfReferralsInsightMetric={
          currentReferralQuery.data?.getCostOfReferralsInsightMetric
        }
        previousCostOfReferralsInsightMetric={
          previousReferralQuery.data?.getCostOfReferralsInsightMetric
        }
      />
      {(() => {
        const [previousHoursWorkedData, currentHoursWorkedData] = [
          previousReferralQuery,
          currentReferralQuery,
        ].map((query) => query.data?.getReferralHoursWorkedInsightMetric);

        if (
          previousHoursWorkedData?.__typename !==
            "IneligibleReferralHoursWorkedInsightMetric" &&
          currentHoursWorkedData?.__typename !==
            "IneligibleReferralHoursWorkedInsightMetric"
        ) {
          return (
            <ReferralHoursWorkedInsightMetric
              currentReferralHoursWorkedInsightMetric={currentHoursWorkedData}
              previousReferralHoursWorkedInsightMetric={previousHoursWorkedData}
            />
          );
        }
        return null;
      })()}
    </InsightMetricGroup>
  );
}
