/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormSetValue,
  get,
  useFormState,
  useWatch,
} from "react-hook-form";

import { SwitchField } from "@rewards-web/shared/components/switch";
import { TextField } from "@rewards-web/shared/components/text-field";
import { Typography } from "@rewards-web/shared/components/typography";
import { formatDollars } from "@rewards-web/shared/lib/format-dollars";
import { AppTheme } from "@rewards-web/shared/style/types";

import { DollarIcon } from "../icons/dollar-icon";
import { InfoIcon } from "../icons/info-icon";

const INTEGERS_REGEX = /^([+-]?[1-9]\d*|0)$/;
const MIN_VALUE = 1;
const MAX_VALUE = 100_000; // $1,000

interface BudgetDefaultPointsFieldProps<T extends FieldValues> {
  control: Control<T>;
  enabledSwitchName: Path<T>;
  name: Path<T>;
  setValue: UseFormSetValue<T>;
  pointsPerDollar: number;
}

export function BudgetDefaultPointsField<T extends FieldValues>({
  control,
  enabledSwitchName,
  name,
  setValue,
  pointsPerDollar,
}: BudgetDefaultPointsFieldProps<T>) {
  const formState = useFormState({ control });
  const switchValue: boolean = useWatch({ control, name: enabledSwitchName });
  const defaultPointsValue: string = useWatch({ control, name });

  return (
    <div
      css={(theme: AppTheme) => css`
        border-radius: 10px;
        border: 1px solid ${theme.palette.grey[400]};
        padding: ${theme.spacing(2)};
        padding-bottom: 0;
        margin-top: ${theme.spacing(3)};
        margin-bottom: ${theme.spacing(4)};
      `}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={(theme: AppTheme) => css`
            padding-right: ${theme.spacing(2)};
          `}
        >
          <DollarIcon />
        </div>
        <div
          css={css`
            flex-grow: 1;
          `}
        >
          <div
            css={(theme: AppTheme) => css`
              display: flex;
              justify-content: space-between;
              margin-bottom: ${theme.spacing(3)};
            `}
          >
            <div>
              <Typography
                variant="subtitle"
                color="textPrimary"
                css={(theme: AppTheme) => css`
                  margin-bottom: ${theme.spacing(0.5)};
                `}
              >
                Budget Access
              </Typography>
              <Typography variant="footnote" color="grey.800">
                Allocate a budget for this admin to give recognition
              </Typography>
            </div>
            <Controller
              control={control}
              name={enabledSwitchName}
              render={({ field }) => (
                <SwitchField
                  css={css`
                    margin-right: 0;
                  `}
                  label="Budget access enabled"
                  hideLabel
                  {...field}
                  value={field.value ?? false}
                  onChange={(e, value) => {
                    field.onChange(e, value);
                    if (!value) {
                      // clear the actual point value, so it isn't
                      // actually used on submission
                      setValue(name, "" as any);
                    }
                  }}
                />
              )}
            />
          </div>

          {switchValue && (
            <TextField
              type="text"
              label="Budget amount"
              placeholder="0 points"
              disableAutocomplete
              endAdornment={
                defaultPointsValue &&
                defaultPointsValue.match(INTEGERS_REGEX) ? (
                  <Typography variant="body" fontWeight={600} color="grey.800">
                    {formatDollars(
                      Number(defaultPointsValue) / pointsPerDollar
                    )}
                  </Typography>
                ) : undefined
              }
              helperText={
                <div
                  css={(theme: AppTheme) => css`
                    display: flex;
                    align-items: center;
                    margin-left: ${theme.spacing(-1.8)};
                  `}
                >
                  <InfoIcon />
                  <span
                    css={(theme: AppTheme) =>
                      css`
                        padding-left: ${theme.spacing(0.5)};
                      `
                    }
                  >
                    The maximum budget amount is 100,000 pts
                  </span>
                </div>
              }
              error={get(formState.errors, name)}
              {...control.register(name, {
                required: switchValue
                  ? "If enabled, budget points are required"
                  : undefined,
                validate: (value) => {
                  if (value !== "") {
                    if (!value.match(INTEGERS_REGEX)) {
                      return "You can only enter numbers (no decimals)";
                    }
                    if (
                      parseInt(value, 10) < MIN_VALUE ||
                      parseInt(value, 10) > MAX_VALUE
                    ) {
                      return "Must add a budget amount between 0 and 100,000 pts";
                    }
                  }
                },
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
}
