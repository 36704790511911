import {
  AppThemeProperties,
  DEFAULT_APP_THEME,
} from "@rewards-web/shared/style/theme";
import { DeepPartial } from "@rewards-web/shared/types";

export const adminAppTheme: DeepPartial<AppThemeProperties> = {
  defaultButtonSize: "large",
  defaultTextFieldSize: "large",
  typography: {
    subtitle: {
      fontWeight: 600,
      fontSize: "1.115rem", // 18px
      lineHeight: "1.688rem", // 27px
    },
    footnote: {
      fontWeight: 400,
      fontSize: "0.85rem",
      lineHeight: "1.3rem",
    },
  },
  palette: {
    text: {
      primary: "#2C393F",
      secondary: DEFAULT_APP_THEME.palette.grey[800],
    },
  },
};
