import {
  addDays,
  addMonths,
  differenceInDays,
  getQuarter,
  parseISO,
  startOfMonth,
  startOfQuarter,
  subDays,
  subMonths,
  subQuarters,
} from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { compact, invert, minBy } from "lodash";

import {
  RewardReportDataSetConfigInput,
  RewardReportDataSetType,
  RewardsAdminRoleType,
  RewardsOrganizationAutomaticRecognitionBudgetFrequency,
} from "@rewards-web/shared/graphql-types";
import { assertNever } from "@rewards-web/shared/lib/assert-never";

import { UseRoleData } from "../../../../shared/modules/role";
import { DownloadReportModalDataQuery } from "./download-report-modal-data.generated";
import {
  ReportDateRangeFormValue,
  ReportDateRangeFormValuePreset,
  ReportDateRangeMonth,
  reportDateRangePresetToDateRange,
  ReportDateRangeQuarter,
} from "./report-date-range-field/lib";

export const DOWNLOAD_REPORT_FORM_VALUE_KEYS = [
  "adminRecognitionBudgetUtilization",
  "adminLogin",
  "caregiverEngagement",
  "caregiverEVVCompliance",
] as const;

type DownloadReportFormValueKey = typeof DOWNLOAD_REPORT_FORM_VALUE_KEYS[number];

export type DownloadReportFormValues = Record<
  DownloadReportFormValueKey,
  boolean
> &
  Record<`${DownloadReportFormValueKey}Range`, ReportDateRangeFormValue>;

export const DOWNLOAD_REPORT_FORM_KEY_TO_DATASET_TYPE: Record<
  keyof Omit<DownloadReportFormValues, `${DownloadReportFormValueKey}Range`>,
  RewardReportDataSetType
> = {
  adminRecognitionBudgetUtilization:
    RewardReportDataSetType.RecognitionBudgetUtilizationByAdmin,
  adminLogin: RewardReportDataSetType.LoginSummaryByAdmin,
  caregiverEngagement: RewardReportDataSetType.EngagementByCaregiver,
  caregiverEVVCompliance: RewardReportDataSetType.EvvComplianceByCaregiver,
};

export const DOWNLOAD_REPORT_DATASET_TYPE_TO_REPORT_FORM_KEY = invert(
  DOWNLOAD_REPORT_FORM_KEY_TO_DATASET_TYPE
) as Record<
  RewardReportDataSetType,
  keyof Omit<DownloadReportFormValues, `${DownloadReportFormValueKey}Range`>
>;

type AdminDataSet =
  | RewardReportDataSetType.RecognitionBudgetUtilizationByAdmin
  | RewardReportDataSetType.LoginSummaryByAdmin;

type AvailableDataSetAndRangeOptions<T extends RewardReportDataSetType> = {
  type: T;

  /**
   * The date range options that the user can select.
   *
   * If the report is not available yet (because not enough time has passed),
   * this array could be empty.
   */
  dateRangeOptions: ReportDateRangeFormValuePreset[];

  customRangeVariant: "date" | "month";

  customRangeMin: Date;
  customRangeMax: Date;

  /**
   * The default option to display to the user, such that
   * if they check the report checkbox, the date range field defaults
   * to this option.
   */
  defaultOption: ReportDateRangeFormValuePreset | null;

  /**
   * If the report is not available, indicates the date that it becomes available.
   */
  reportsAvailableAt?: Date;
};

/**
 * Given API data and the current date, returns the available
 * dataset types (checkboxes), the possible date range options for each,
 * and the default value to display to the user.
 */
export function getAvailableAdminDataSetsAndRangeOptions(
  roleData: UseRoleData,
  modalData: DownloadReportModalDataQuery,
  now: Date
): AvailableDataSetAndRangeOptions<AdminDataSet>[] {
  const { timezone, launchedAt } = modalData.getMyRewardsOrganization;

  if (!launchedAt) {
    return [];
  }

  const availableAdminDataSets: AvailableDataSetAndRangeOptions<AdminDataSet>[] = [];

  const adminIsEligibleToViewAdminReports =
    roleData.role === "superuser" ||
    (modalData.getMyRewardsAdminUser?.role &&
      [
        RewardsAdminRoleType.ProgramManager,
        RewardsAdminRoleType.HumanResources,
        RewardsAdminRoleType.Executive,
      ].includes(modalData.getMyRewardsAdminUser.role));

  if (adminIsEligibleToViewAdminReports) {
    if (
      modalData.getMyRewardsOrganization.recognitionBudgetsEnabled &&
      modalData.getMyRewardsOrganization.automaticRecognitionBudgetConfig
        .frequency ===
        RewardsOrganizationAutomaticRecognitionBudgetFrequency.Monthly
    ) {
      // only orgs using monthly recognition budget can currently export this report
      // (because they can select month-granularity, which is assumed to line up
      // with the budgets)

      availableAdminDataSets.push({
        ...getAvailableRangeOptions({
          customRangeVariant: "month",
          dataSetType:
            RewardReportDataSetType.RecognitionBudgetUtilizationByAdmin,
          supportedRangeOptions: compact([
            "current_month",
            "last_month",
            "current_quarter",
            "last_quarter",
            roleData.role === "superuser" && "custom",
          ]),
          desiredDefaultOption: "current_month",
          now: now,
          timezone,
          earliestDateAvailable: launchedAt,
        }),
      });
    }

    // login summary by admin is available to all admins

    availableAdminDataSets.push({
      ...getAvailableRangeOptions({
        customRangeVariant: "date",
        dataSetType: RewardReportDataSetType.LoginSummaryByAdmin,
        supportedRangeOptions: compact([
          "last_7_days",
          "last_30_days",
          "last_90_days",
          "current_month",
          "last_month",
          "current_quarter",
          "last_quarter",
          roleData.role === "superuser" && "custom",
        ]),
        desiredDefaultOption: "last_30_days",
        now: now,
        timezone,
        earliestDateAvailable: launchedAt,
      }),
    });
  }

  return availableAdminDataSets;
}

type CaregiverDataSet =
  | RewardReportDataSetType.EngagementByCaregiver
  | RewardReportDataSetType.EvvComplianceByCaregiver;

export function getAvailableCaregiverDataSetsAndRangeOptions(
  roleData: UseRoleData,
  modalData: DownloadReportModalDataQuery,
  now: Date
): AvailableDataSetAndRangeOptions<CaregiverDataSet>[] {
  const { timezone, launchedAt } = modalData.getMyRewardsOrganization;

  if (!launchedAt) {
    return [];
  }

  const availableCaregiverDataSets: AvailableDataSetAndRangeOptions<CaregiverDataSet>[] = [];

  availableCaregiverDataSets.push({
    ...getAvailableRangeOptions({
      customRangeVariant: "date",
      dataSetType: RewardReportDataSetType.EngagementByCaregiver,
      supportedRangeOptions: compact([
        "last_30_days",
        "last_90_days",
        roleData.role === "superuser" && "custom",
      ]),
      desiredDefaultOption: "last_30_days",
      now: now,
      timezone,
      earliestDateAvailable: launchedAt,
    }),
  });

  if (modalData.getMyRewardsOrganization.hasVisitData) {
    // only orgs with visit data can view the EVV compliance report
    const nowInZone = utcToZonedTime(now, timezone);
    const startOfMonthInZone = startOfMonth(nowInZone);
    const startOfQuarterInZone = startOfQuarter(nowInZone);

    const supportedRangeOptions: ReportDateRangeFormValuePreset[] = [];

    // if it hasn't been 14 days since the start of the month yet,
    // show 2 months ago as an option
    // (since we can't show EVV data within the past 14 days)
    if (differenceInDays(nowInZone, startOfMonthInZone) < 14) {
      const startOf2MonthsAgo = subMonths(startOfMonthInZone, 2);

      supportedRangeOptions.push(
        `specific_month:${startOf2MonthsAgo.getFullYear()}:${
          (startOf2MonthsAgo.getMonth() + 1) as ReportDateRangeMonth
        }`
      );
    } else {
      supportedRangeOptions.push("last_month");
    }

    // if it hasn't been 14 days since the start of the month yet,
    // show 2 months ago as an option
    // (since we can't show EVV data within the past 14 days)
    if (differenceInDays(nowInZone, startOfQuarterInZone) < 14) {
      const startOf2QuartersAgo = subQuarters(startOfQuarterInZone, 2);

      supportedRangeOptions.push(
        `specific_quarter:${startOf2QuartersAgo.getFullYear() as any}:${
          getQuarter(startOf2QuartersAgo) as ReportDateRangeQuarter
        }`
      );
    } else {
      supportedRangeOptions.push("last_quarter");
    }

    availableCaregiverDataSets.push({
      ...getAvailableRangeOptions({
        customRangeVariant: "date",
        dataSetType: RewardReportDataSetType.EvvComplianceByCaregiver,
        supportedRangeOptions: compact([
          ...supportedRangeOptions,
          roleData.role === "superuser" && "custom",
        ]),
        desiredDefaultOption: supportedRangeOptions[0],
        now,
        latestDateAvailable: subDays(now, 14), // EVV reports are not available within past 14 days
        timezone,
        earliestDateAvailable: launchedAt,
      }),
    });
  }

  return availableCaregiverDataSets;
}

/**
 * Convenience function which produces the available date range
 * options for a given set of supported range options, earliest/latest
 * available dates and desired default option.
 */
function getAvailableRangeOptions<
  T extends RewardReportDataSetType,
  O extends ReportDateRangeFormValuePreset
>(params: {
  dataSetType: T;
  supportedRangeOptions: O[];
  desiredDefaultOption: O;
  now: Date;
  timezone: string;

  customRangeVariant: "date" | "month";

  /**
   * Latest date that should be available.
   *
   * If not provided, there is no latest date available.
   */
  latestDateAvailable?: Date;

  /**
   * Earliest date that should be available.
   */
  earliestDateAvailable: Date | number;
}): AvailableDataSetAndRangeOptions<any> {
  const rangeOptionsWithDateRanges = params.supportedRangeOptions.map(
    (option) => ({
      option,
      range:
        option === "custom"
          ? null
          : reportDateRangePresetToDateRange(
              option as Exclude<ReportDateRangeFormValuePreset, "custom">,
              params.now,
              params.timezone
            ),
    })
  );

  const availableRangeOptionsWithDateRanges = rangeOptionsWithDateRanges.filter(
    (option) => {
      if (
        params.latestDateAvailable &&
        params.latestDateAvailable < new Date(params.earliestDateAvailable)
      ) {
        // no ranges are possible, since latest date available is before earliest date available
        return false;
      }

      if (option.option === "custom") {
        // for custom, the earliest date available must be in the past
        return new Date(params.earliestDateAvailable) <= params.now;
      }

      if (
        params.latestDateAvailable &&
        params.latestDateAvailable <= option.range!.endUTC
      ) {
        // the latest date available is before the range's end date,
        // so we can't include this range
        return false;
      }

      if (
        option.option === "last_7_days" ||
        option.option === "last_30_days" ||
        option.option === "last_90_days"
      ) {
        // for other preset date ranges, the earliest/latest date available
        // must engulf the provided range
        return new Date(params.earliestDateAvailable) <= option.range!.startUTC;
      }

      // for other preset date ranges, the range must not be earlier
      // than the earliest date available
      return new Date(params.earliestDateAvailable) <= option.range!.endUTC;
    }
  );

  const defaultOption =
    availableRangeOptionsWithDateRanges.find(
      (option) => option.option === params.desiredDefaultOption
    ) ??
    // fall back to the first available option (if exists)
    availableRangeOptionsWithDateRanges[0];

  return {
    type: params.dataSetType,
    dateRangeOptions: availableRangeOptionsWithDateRanges.map(
      (option) => option.option
    ),

    customRangeMin: new Date(params.earliestDateAvailable),
    customRangeMax: params.latestDateAvailable ?? params.now,
    customRangeVariant: params.customRangeVariant,
    defaultOption: defaultOption?.option ?? null,
    reportsAvailableAt:
      availableRangeOptionsWithDateRanges.length === 0
        ? minBy(
            rangeOptionsWithDateRanges,
            (option) => option.range && option.range.endUTC
          )!.range?.endUTC
        : undefined,
  };
}

export function serializeFormValuesForApi(
  availableDataSets: AvailableDataSetAndRangeOptions<RewardReportDataSetType>[],
  values: DownloadReportFormValues,
  now: Date,
  timezone: string
) {
  return availableDataSets.reduce<RewardReportDataSetConfigInput[]>(
    (prev, dataSet): RewardReportDataSetConfigInput[] => {
      const enabled =
        values[DOWNLOAD_REPORT_DATASET_TYPE_TO_REPORT_FORM_KEY[dataSet.type]];

      if (!enabled) {
        // dataset is not enabled in the form, so we won't add it to the datasets
        // to be sent to the api
        return prev;
      }

      const rangeVariant: "month" | "date" = (() => {
        switch (dataSet.type) {
          case RewardReportDataSetType.RecognitionBudgetUtilizationByAdmin:
            return "month";
          default:
            return "date";
        }
      })();

      const range = formDateRangeToApiRange(
        values[
          `${
            DOWNLOAD_REPORT_DATASET_TYPE_TO_REPORT_FORM_KEY[dataSet.type]
          }Range`
        ],
        now,
        rangeVariant,
        timezone
      );

      return [
        ...prev,
        {
          type: dataSet.type,
          recognitionBudgetUtilizationByAdminParameters:
            dataSet.type ===
            RewardReportDataSetType.RecognitionBudgetUtilizationByAdmin
              ? {
                  startMonth: range.start,
                  endMonth: range.end,
                }
              : undefined,
          engagementByCaregiverParameters:
            dataSet.type === RewardReportDataSetType.EngagementByCaregiver
              ? {
                  dateRangeStart: range.start,
                  dateRangeEnd: range.end,
                }
              : undefined,
          evvComplianceByCaregiverParameters:
            dataSet.type === RewardReportDataSetType.EvvComplianceByCaregiver
              ? {
                  dateRangeStart: range.start,
                  dateRangeEnd: range.end,
                }
              : undefined,
          loginSummaryByAdminParameters:
            dataSet.type === RewardReportDataSetType.LoginSummaryByAdmin
              ? {
                  dateRangeStart: range.start,
                  dateRangeEnd: range.end,
                }
              : undefined,
        },
      ];
    },
    []
  );
}

function formDateRangeToApiRange(
  value: NonNullable<ReportDateRangeFormValue>,
  now: Date,
  rangeVariant: "date" | "month",
  timezone: string
): { start: string; end: string } {
  if (!value.preset) {
    // this shouldn't happen
    throw new Error("No preset defined");
  }

  if (value.preset === "custom") {
    if (!value.customStart || !value.customEnd) {
      // this shouldn't happen since the date fields
      // should be required when 'custom' is selected
      throw new Error("No custom dates defined");
    }

    switch (rangeVariant) {
      // add date to the end date,
      // since API uses exclusive end dates,
      // but date selector uses inclusive end dates

      // then convert to an ISO date, using start of the timezone's date

      case "date":
        return {
          start: zonedTimeToUtc(
            parseISO(value.customStart),
            timezone
          ).toISOString(),
          end: zonedTimeToUtc(
            parseISO(addDateToDateString(value.customEnd)),
            timezone
          ).toISOString(),
        };
      case "month":
        return {
          start: value.customStart,
          end: addMonthToMonthString(value.customEnd),
        };
      default:
        assertNever(rangeVariant);
    }
  }

  // for non-custom preset, use the computed date range,
  // and format it according to the range variant
  // in the given timezone

  const dateRange = reportDateRangePresetToDateRange(
    value.preset,
    now,
    timezone
  );

  switch (rangeVariant) {
    case "month":
      return {
        start: formatDateToMonthInTimezone(dateRange.startUTC, timezone),
        end: formatDateToMonthInTimezone(dateRange.endUTC, timezone),
      };
    case "date":
      return {
        start: dateRange.startUTC.toISOString(),
        end: dateRange.endUTC.toISOString(),
      };
    default:
      assertNever(rangeVariant);
  }
}

function formatDateToMonthInTimezone(date: Date, timezone: string) {
  return format(utcToZonedTime(date, timezone), "yyyy-MM", {
    timeZone: timezone,
  });
}

/**
 * Given a month and timezone, adds an extra month to the end date
 *
 * e.g. "2024-05" -> "2024-06"
 */
function addMonthToMonthString(month: string): string {
  return addMonths(new Date(month), 1)
    .toISOString()
    .split("T")[0]
    .split("-")
    .slice(0, 2)
    .join("-");
}

/**
 * Given a date and timezone, adds an extra day to the end date
 *
 * e.g. "2024-05-01" -> "2024-05-02"
 */
function addDateToDateString(date: string): string {
  return addDays(new Date(date), 1).toISOString().split("T")[0];
}
