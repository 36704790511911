import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EligibleReferralHoursWorkedInsightMetric } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export interface ReferralHoursWorkedInsightMetricProps {
  currentReferralHoursWorkedInsightMetric:
    | EligibleReferralHoursWorkedInsightMetric
    | undefined;
  previousReferralHoursWorkedInsightMetric:
    | EligibleReferralHoursWorkedInsightMetric
    | undefined;
}

export function ReferralHoursWorkedInsightMetric({
  currentReferralHoursWorkedInsightMetric,
  previousReferralHoursWorkedInsightMetric,
}: ReferralHoursWorkedInsightMetricProps) {
  const currentHoursWorked =
    currentReferralHoursWorkedInsightMetric?.numHoursWorked;
  const percentPointChange = (() => {
    const previousHoursWorked =
      previousReferralHoursWorkedInsightMetric?.numHoursWorked;
    if (currentHoursWorked === undefined || previousHoursWorked === undefined) {
      return null;
    }
    return getPercentDifference(previousHoursWorked, currentHoursWorked);
  })();

  return (
    <InsightMetric
      label="Hours worked"
      icon={<FontAwesomeIcon icon={faClock} />}
      tooltipText="Total hours worked by referred employees, even if hired earlier"
      value={
        currentHoursWorked !== undefined
          ? {
              type: "number",
              number: currentHoursWorked,
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
