import { Alert } from "@rewards-web/shared/components/alert";
import { reportError } from "@rewards-web/shared/modules/error";

import { InsightMetricGroup } from "../../components/insight-metric-group";
import starIconUrl from "../../graphics/star-icon.png";
import { useEngagementInsightMetricGroupDataQuery } from "./engagement-metric-group-query.generated";
import { ActiveUsersInsightMetric } from "./insight-metrics/active-users";
import { PointsAwardedInsightMetric } from "./insight-metrics/points-awarded";
import { PointsRedeemedInsightMetric } from "./insight-metrics/points-redeemed";
import { UserLoginsInsightMetric } from "./insight-metrics/user-logins";

export interface EngagementInsightMetricGroupProps {
  currentRange: {
    startDate: Date;
    endDate: Date;
  };
  previousRange: {
    startDate: Date;
    endDate: Date;
  } | null;
  pointsPerDollar: number;
  timezone: string;
}

export function EngagementInsightMetricGroup({
  currentRange,
  previousRange,
  pointsPerDollar,
  timezone,
}: EngagementInsightMetricGroupProps) {
  const skipPreviousRangeQuery = !previousRange;

  const currentEngagementQuery = useEngagementInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: currentRange.startDate.valueOf(),
      endDate: currentRange.endDate.valueOf(),
    },
  });

  const previousEngagementQuery = useEngagementInsightMetricGroupDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      startDate: previousRange?.startDate.valueOf(),
      endDate: previousRange?.endDate.valueOf(),
    },
    skip: skipPreviousRangeQuery,
  });

  const queriesToValidate = [
    currentEngagementQuery,
    ...(skipPreviousRangeQuery ? [] : [previousEngagementQuery]),
    // no need to validate previous range queries if we skip them
  ];

  if (
    queriesToValidate.some(
      (query) => !query.loading && (query.error || !query.data)
    )
  ) {
    reportError(
      new Error(
        `Empty engagement metric queries after loading: ${JSON.stringify(
          queriesToValidate.map(({ error }) => ({ error }))
        )}`
      )
    );
    return (
      <Alert
        severity="error"
        message="Something went wrong. Please try again later."
      />
    );
  }

  return (
    <InsightMetricGroup
      label="Engagement"
      icon={<img src={starIconUrl} width={24} alt="star" />}
      dateRange={currentRange}
      timezone={timezone}
    >
      <ActiveUsersInsightMetric
        currentActiveUsersInsightMetric={
          currentEngagementQuery.data?.getActiveUsersInsightMetric
        }
        previousActiveUsersInsightMetric={
          previousEngagementQuery.data?.getActiveUsersInsightMetric
        }
      />
      <UserLoginsInsightMetric
        currentUserLoginsInsightMetric={
          currentEngagementQuery.data?.getLoggedInUsersInsightMetric
        }
        previousUserLoginsInsightMetric={
          previousEngagementQuery.data?.getLoggedInUsersInsightMetric
        }
      />
      <PointsAwardedInsightMetric
        pointsPerDollar={pointsPerDollar}
        currentPointsAwardedInsightMetric={
          currentEngagementQuery.data?.getPointsAwardedInsightMetric
        }
        previousPointsAwardedInsightMetric={
          previousEngagementQuery.data?.getPointsAwardedInsightMetric
        }
      />
      <PointsRedeemedInsightMetric
        pointsPerDollar={pointsPerDollar}
        currentPointsRedeemedInsightMetric={
          currentEngagementQuery.data?.getPointsRedeemedInsightMetric
        }
        previousPointsRedeemedInsightMetric={
          previousEngagementQuery.data?.getPointsRedeemedInsightMetric
        }
      />
    </InsightMetricGroup>
  );
}
