import { Typography } from "@rewards-web/shared/components/typography";
import { RecognitionPointsInsightMetric } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export type AwardedRecognitionInsightMetricProps = {
  pointsPerDollar: number;
  currentRecognitionPointsInsightMetric:
    | RecognitionPointsInsightMetric
    | undefined;
  previousRecognitionPointsInsightMetric:
    | RecognitionPointsInsightMetric
    | undefined;
};

export function AwardedRecognitionInsightMetric({
  currentRecognitionPointsInsightMetric,
  previousRecognitionPointsInsightMetric,
  pointsPerDollar,
}: AwardedRecognitionInsightMetricProps) {
  const currentRecognitionAwarded =
    currentRecognitionPointsInsightMetric?.bonusPointsAwarded;

  const percentPointChange = (() => {
    const previousRecognitionAwarded =
      previousRecognitionPointsInsightMetric?.bonusPointsAwarded;
    if (
      currentRecognitionAwarded === undefined ||
      previousRecognitionAwarded === undefined
    ) {
      return null;
    }
    return getPercentDifference(
      previousRecognitionAwarded,
      currentRecognitionAwarded
    );
  })();

  return (
    <InsightMetric
      label="Awarded recognition"
      icon={
        <Typography variant="body" fontSize={18}>
          $
        </Typography>
      }
      tooltipText="Sum of all recognition points awarded, including budgeted and non-budgeted"
      value={
        currentRecognitionAwarded !== undefined
          ? {
              type: "dollar",
              points: currentRecognitionAwarded,
              pointsPerDollar,
            }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
