import * as Types from '@rewards-web/shared/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type EngagementInsightMetricGroupDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Timestamp'];
  endDate: Types.Scalars['Timestamp'];
}>;


export type EngagementInsightMetricGroupDataQuery = (
  { __typename?: 'Query' }
  & { getActiveUsersInsightMetric: (
    { __typename?: 'ActiveUsersInsightMetric' }
    & Pick<Types.ActiveUsersInsightMetric, 'id' | 'activeUserCount'>
  ), getPointsAwardedInsightMetric: (
    { __typename?: 'PointsAwardedInsightMetric' }
    & Pick<Types.PointsAwardedInsightMetric, 'id' | 'pointsAwarded'>
  ), getPointsRedeemedInsightMetric: (
    { __typename?: 'PointsRedeemedInsightMetric' }
    & Pick<Types.PointsRedeemedInsightMetric, 'id' | 'pointsRedeemed'>
  ), getLoggedInUsersInsightMetric: (
    { __typename?: 'LoggedInUsersInsightMetric' }
    & Pick<Types.LoggedInUsersInsightMetric, 'id' | 'usersWhoLoggedInCount' | 'activeUserCount'>
  ) }
);


export const EngagementInsightMetricGroupDataDocument = gql`
    query EngagementInsightMetricGroupData($startDate: Timestamp!, $endDate: Timestamp!) {
  getActiveUsersInsightMetric(endDate: $endDate) {
    id
    activeUserCount
  }
  getPointsAwardedInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    pointsAwarded
  }
  getPointsRedeemedInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    pointsRedeemed
  }
  getLoggedInUsersInsightMetric(startDate: $startDate, endDate: $endDate) {
    id
    usersWhoLoggedInCount
    activeUserCount
  }
}
    `;

/**
 * __useEngagementInsightMetricGroupDataQuery__
 *
 * To run a query within a React component, call `useEngagementInsightMetricGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementInsightMetricGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementInsightMetricGroupDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEngagementInsightMetricGroupDataQuery(baseOptions: Apollo.QueryHookOptions<EngagementInsightMetricGroupDataQuery, EngagementInsightMetricGroupDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EngagementInsightMetricGroupDataQuery, EngagementInsightMetricGroupDataQueryVariables>(EngagementInsightMetricGroupDataDocument, options);
      }
export function useEngagementInsightMetricGroupDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EngagementInsightMetricGroupDataQuery, EngagementInsightMetricGroupDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EngagementInsightMetricGroupDataQuery, EngagementInsightMetricGroupDataQueryVariables>(EngagementInsightMetricGroupDataDocument, options);
        }
export type EngagementInsightMetricGroupDataQueryHookResult = ReturnType<typeof useEngagementInsightMetricGroupDataQuery>;
export type EngagementInsightMetricGroupDataLazyQueryHookResult = ReturnType<typeof useEngagementInsightMetricGroupDataLazyQuery>;
export type EngagementInsightMetricGroupDataQueryResult = Apollo.QueryResult<EngagementInsightMetricGroupDataQuery, EngagementInsightMetricGroupDataQueryVariables>;