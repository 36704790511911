import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AdminsUsingRecognitionInsightMetric as AdminsUsingRecognitionInsightMetricType } from "@rewards-web/shared/graphql-types";

import { InsightMetric } from "../../../../components/insight-metric";
import { getPercentDifference } from "../../../../utils";

export interface AdminsUsingRecognitionInsightMetricProps {
  currentAdminsUsingRecognitionInsightMetric:
    | AdminsUsingRecognitionInsightMetricType
    | undefined;
  previousAdminsUsingRecognitionInsightMetric:
    | AdminsUsingRecognitionInsightMetricType
    | undefined;
}

export function AdminsUsingRecognitionInsightMetric({
  currentAdminsUsingRecognitionInsightMetric,
  previousAdminsUsingRecognitionInsightMetric,
}: AdminsUsingRecognitionInsightMetricProps) {
  const currentAdminsUsingRecognition =
    currentAdminsUsingRecognitionInsightMetric?.adminsUsingRecognitionCount;

  const percentPointChange = (() => {
    const previousAdminsUsingRecognition =
      previousAdminsUsingRecognitionInsightMetric?.adminsUsingRecognitionCount;
    if (
      currentAdminsUsingRecognition === undefined ||
      previousAdminsUsingRecognition === undefined
    ) {
      return null;
    }
    return getPercentDifference(
      previousAdminsUsingRecognition,
      currentAdminsUsingRecognition
    );
  })();

  return (
    <InsightMetric
      label="Admins using recognition"
      icon={<FontAwesomeIcon icon={faUsers} />}
      tooltipText="Number of admins who gave recognition to employees"
      value={
        currentAdminsUsingRecognition !== undefined
          ? { type: "number", number: currentAdminsUsingRecognition }
          : {
              type: "loading",
            }
      }
      percentPointChange={percentPointChange}
    />
  );
}
