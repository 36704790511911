/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { subDays } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { ReactNode } from "react";

import { Card } from "@rewards-web/shared/components/card";
import { Typography } from "@rewards-web/shared/components/typography";
import { AppTheme } from "@rewards-web/shared/style/types";

export interface InsightMetricGroupProps {
  label: string;
  icon: JSX.Element;
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  timezone: string;
  children: ReactNode[];
}
export function InsightMetricGroup(props: InsightMetricGroupProps) {
  const theme = useTheme();

  const dateRangeText = (() => {
    const { startDate, endDate } = props.dateRange;
    const startMonthStr = formatInTimeZone(startDate, props.timezone, "MMM");
    const endMonthStr = formatInTimeZone(
      subDays(endDate, 1),
      props.timezone,
      "MMM"
    );
    const yearStr = formatInTimeZone(startDate, props.timezone, "yyyy");

    const monthStr = (() => {
      if (startMonthStr === endMonthStr) {
        return startMonthStr;
      }
      return `${startMonthStr} - ${endMonthStr}`;
    })();

    return `${monthStr} ${yearStr}`;
  })();

  return (
    <Card
      css={css`
        padding: ${theme.spacing(3)};
      `}
      variant="outlined"
    >
      <div
        css={css`
          display: flex;
          margin-top: ${theme.spacing(1.25)};
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <div
          css={css`
            margin-right: ${theme.spacing(1.25)};
          `}
        >
          {props.icon}
        </div>
        <Typography variant="h2" fontWeight={700}>
          {props.label}
        </Typography>
        <div
          css={css`
            margin-left: auto;
          `}
        >
          <Typography color={theme.palette.grey[600]} fontWeight={700}>
            {dateRangeText}
          </Typography>
        </div>
      </div>
      <div
        css={(theme: AppTheme) =>
          css`
            display: flex;
            gap: ${theme.spacing(2)};
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          `
        }
      >
        {props.children}
      </div>
    </Card>
  );
}
